<template>
  <div class="details">
    <div class="details--header" @click="editMode = !editMode">
      <h2 class="details--header-title">Coordonnées du bien à rénover</h2>
      <EditButton
        :editMode="editMode"
        :loading="editButtonLoading"
        @onSave="
          $emit('onSave', { projectAddress: model, userPhone: phoneField })
        "
      />
    </div>
    <div class="details--body">
      <keep-alive>
        <component
          :is="component"
          :model="projectAddress"
          @updateModel="(payload) => Object.assign(model, payload)"
          @updatePhone="(payload) => (phoneField = payload)"
        ></component>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
  name: "ContactDetails",
  components: {
    ContactDetailsEdit: () => import("./ContactDetailsEdit.vue"),
    ContactDetailsView: () => import("./ContactDetailsView.vue"),
    EditButton: () => import("~ui/EditButton/EditButton.vue"),
  },
  props: {
    editButtonLoading: {
      type: Boolean,
    },
  },
  data() {
    return {
      editMode: false,
      model: {},
      phoneField: "",
    };
  },
  computed: {
    component() {
      return this.editMode ? "ContactDetailsEdit" : "ContactDetailsView";
    },
    ...mapGetters({
      projectAddress: "project/getCurrentProjectAddress"
    })
  },
  methods: {
    onSave() {},
  },
};
</script>

<style></style>
